const fetchNow = async (sub, method, body, headers, not_token) => {
    let url = process.env.REACT_APP_API + sub;
    let response;
    
    if(body) {
        if(headers) {
            response = await fetch(url, {
                method: method,
                headers: (not_token) ? {
                    'Content-type': 'application/json'
                } : {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-type': 'application/json'
                },
                body: JSON.stringify(body)
            });
        }else {
            response = await fetch(url, {
                method: method,
                credentials: 'include',
                headers: (not_token) ? {
                    'Content-type': 'application/x-www-form-urlencoded'
                } : {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-type': 'application/x-www-form-urlencoded'
                },
                body: body
            });
        }
    }else {
        response = await fetch(url, (not_token) ? {
            method: method,
        } : {
            method: method,
            headers:  {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
        });
    }

    if(response.status === 500){
        return {
            status: 500,
            message: 'Internal Server Error'
        }
    }else if(response.status === 403){
        let result = await response.text();

        if(result.includes('Access denied')) {
            localStorage.clear();
            window.location.reload();
        }else{
            return JSON.parse(result);
        }
    }else{
        return await response.json();
    }
}

export default fetchNow;