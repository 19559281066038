import React, { Component } from 'react';

import LogoSemarang from '../asset/img/Lambang_Kota_Semarang.png';
import CardDecoration from '../asset/img/Card-Decoration.svg';
import Illustration2FA from '../asset/img/2FA.svg';

import Loading from '../components/Loading';

import AuthAPI from '../api/Auth';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            
            show: 'none',
            redirect: null,

            app_id: new URLSearchParams(this.props.location.search).get('app_id'),
            redirect_uri: new URLSearchParams(this.props.location.search).get('redirect_uri'),

            data: {
                username: '',
                password: ''
            },
            error: {
                title: '',
                subtitle: ''
            },

            code: ""
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleRelogin = this.handleRelogin.bind(this);

        this.handleCode = this.handleCode.bind(this);
        this.verify2fa = this.verify2fa.bind(this);

        this.reset = this.reset.bind(this);
    }

    /* === Login Protocol ======================================================= */

    handleChange(event) {
        /* Store Data */
        let data = this.state.data;

        /* Change Value */
        data[event.target.name] = event.target.value;

        /* Update Data */
        this.setState({
            data: data,
            error: {
                title: '',
                subtitle: ''
            }
        })
    }

    handleLogin(event) {
        /* Prevent Default */
        event.preventDefault();

        this.setState({
            isLoading: true
        }, () => {
            /* Store Request Body */
            const body = new URLSearchParams({
                username: this.state.data.username,
                password: this.state.data.password,
                clientId: this.state.app_id ? this.state.app_id : process.env.REACT_APP_APP_ID
            })

            /* Request to Login */
            AuthAPI.login(body).then((data) => {
                if(data.status === 200) {
                    if(typeof data.data === 'string') {
                        if(data.data.includes('verifikasi email')) {
                            /* Handling for Not Email Verified */
                            this.setState({
                                isLoading: false
                            }, () => {
                                toast.error('Akun Anda belum Terverifikasi, Silahkan Cek Email Anda untuk Melakukan Verifikasi', {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: 'colored',
                                    autoClose: 8000
                                });
                            })
                        }else{
                            /* Handling for 2FA */
                            this.setState({
                                isLoading: false,
                                show: '2fa'
                            })
                        }
                    }else{
                        AuthAPI.getToken(data.sso_code).then((result) => {
                            /* Store Session Data */
                            localStorage.setItem('local_session', result.local_session);
                            localStorage.setItem('session_time', Date.now());
                            localStorage.setItem('announcement', 'no');
    
                            /* Check if Redirect Protocol or SSO Login */
                            if(this.state.app_id) {
                                window.location.href = `${this.state.redirect_uri}?sso_code=${data.sso_code}`;
                            }else{
                                localStorage.setItem('token', result.access_token);
                                localStorage.setItem('refresh_token', result.refresh_token);
                                window.location.reload();
                            }
                        })
                    }
                }else if(data.status === 403) {
                    /* Handle Unauthorized Error */
                    this.setState({
                        isLoading: false,
                        show: 'error',
                        error: {
                            title: 'Akses Tidak Dimiliki',
                            subtitle: 'Akun yang Anda gunakan tidak memiliki akses untuk masuk ke dalam aplikasi.'
                        }
                    })
                }else{
                    /* Default Error */
                    let error = {
                        title:'Terjadi Kesalahan',
                        subtitle: 'Telah terjadi kesalahan, silahkan coba beberapa saat lagi.'
                    }

                    if(data.status){
                        if(data.status === 400 && data.message === 'Username/Password Salah'){
                            /* Handle Password/Username Wrong */
                            error.title = 'Username/Password Salah';
                            error.subtitle = '';
                        }else if(data.status === 400 && data.message === 'Aplikasi Tidak Ditemukan'){
                            /* Handle Apps Doesn't Found */
                            error.title = 'Aplikasi Tidak Tersedia';
                            error.subtitle = 'Aplikasi yang anda tuju tidak ditemukan atau tidak terdaftar didalam sistem.';
                        }
                    }

                    this.setState({
                        isLoading: false,
                        show: (error.subtitle === '') ? this.state.show : 'error',
                        error
                    })
                }
            })
        })
    }

    /* === Login with Session (Relogin) Protocol ================================ */

    handleRelogin() {
        this.setState({
            isLoading: true
        }, () => {
            /* Store Request Body */
            const data_form = new URLSearchParams({
                clientId: this.state.app_id,
                sessionId: localStorage.getItem('local_session')
            })

            /* Request to Relogin */
            AuthAPI.relogin(data_form).then((data) => {
                if(data.status === 200) {        
                    /* Return SSO Code */            
                    window.location.replace(`${this.state.redirect_uri}?sso_code=${data.sso_code}`);
                }else if(data.status === 403) {
                    /* Handle Uanuthorized Access */
                    this.setState({
                        isLoading: false,
                        show: 'error',
                        error: {
                            title: 'Akses Tidak Dimiliki',
                            subtitle: 'Akun yang Anda gunakan tidak memiliki akses untuk masuk ke dalam aplikasi.'
                        }
                    })
                }else if(data.status === 401) {
                    /* Handle Token Expired */
                    localStorage.clear();
                    this.reset();
                }else{
                    /* Handle All Error */
                    this.setState({
                        isLoading: false,
                        show: 'error',
                        error: {
                            title: 'Terjadi Kesalahan',
                            subtitle: 'Telah terjadi kesalahan, silahkan coba beberapa saat lagi.'
                        }
                    })
                }
            })
        })
    }

    /* === Two-Factor Authentication ============================================ */

    handleCode(event) {
        let value = event.target.value;

        if((value.charAt(value.length - 1) >= '0' && value.charAt(value.length - 1) <= '9') || value === ''){
            if(value.length <= 5){
                this.setState({
                    code: value,
                    error: {
                        title: '',
                        subtitle: ''
                    }
                })
            }
        }
    }

    verify2fa(event) {
        /* Prevent Default */
        event.preventDefault();

        this.setState({
            isLoading: true
        }, () => {
            /* Store Request Body */
            const body = new URLSearchParams({
                username: this.state.data.username,
                otp: this.state.code,
                clientId: this.state.app_id ? this.state.app_id : process.env.REACT_APP_APP_ID
            })

            /* Request to Verify Verification Code */
            AuthAPI.verify(body).then((data) => {
                if(data.status === 200) {
                    AuthAPI.getToken(data.sso_code).then((result) => {
                        /* Store Session Data */
                        localStorage.setItem('local_session', result.local_session);
                        localStorage.setItem('session_time', Date.now());
                        localStorage.setItem('announcement', 'no');

                        /* Check if Redirect Protocol or SSO Login */
                        if(this.state.redirect_uri) {
                            window.location.href = `${this.state.redirect_uri}?sso_code=${data.sso_code}`;
                        }else{
                            localStorage.setItem('token', result.access_token);
                            localStorage.setItem('refresh_token', result.refresh_token);
                            window.location.reload();
                        }
                    })
                }else if(data.status === 403) {
                    /* Handle Uanuthorized Access */
                    this.setState({
                        isLoading: false,
                        show: 'error',
                        error: {
                            title: 'Akses Tidak Dimiliki',
                            subtitle: 'Akun yang Anda gunakan tidak memiliki akses untuk masuk ke dalam aplikasi.'
                        }
                    })
                }else{
                    /* Handle Error */
                    this.setState({
                        isLoading: false,
                        error: {
                            title: 'Kode verifikasi salah',
                            subtitle: ''
                        }
                    })
                }
            })

        })
    }

    /* === Reset Login ========================================================== */

    reset() {
        this.setState({
            isLoading: false,
            show: 'login',
            app_id: new URLSearchParams(this.props.location.search).get('app_id'),
            redirect_uri: new URLSearchParams(this.props.location.search).get('redirect_uri'),
            data: {
                username: '',
                password: ''
            },
            error: {
                title: '',
                subtitle: ''
            },
            code: ""
        })
    }

    componentDidMount() {
        /* Get Session in Local Storage */
        let session = localStorage.getItem('local_session');

        /* Check if Session Exist */
        if(session) {
            /* Store Current Time and Session Timestamp */
            let now = moment(Date.now());
            let time = moment(parseInt(localStorage.getItem('session_time')));
            
            /* Check if session has expired */
            if(now.diff(time, 'hour') > 22) {
                localStorage.clear();
                session = null;
            }
        }

        /* Check if App Id and Redirect URI exist */
        if(this.state.app_id && this.state.redirect_uri){
            if(session){
                this.handleRelogin();
            }else{
                this.setState({
                    show: 'login'
                })
            }
        }else{
            if(session){
                this.setState({
                    show: 'login',
                    redirect: <Redirect to="/" />
                })
            }else{
                this.setState({
                    show: 'login'
                })
            }
        }
    }

    render() { 
        /* Show Login Form */
        if(this.state.show === 'login' || this.state.show === 'error')
        {
            return (
                <React.Fragment>
                    { this.state.redirect }
                    <Loading show={this.state.isLoading} />
                    <div className="login-root d-flex align-items-center justify-content-center">
                        <div className="login-card">
                            <div className="row">
                                <div className="login-card-header col-md-6 p-0 d-flex flex-column align-items-center justify-content-between">
                                    <div></div>
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        <img className="login-logo" src={LogoSemarang} alt="Logo Kota Semarang" />
                                        <h1>Single Sign On</h1>
                                        <div className="subtitle">Pemerintah Kota Semarang</div>
                                    </div>
                                    <img className="login-card-decoration" src={CardDecoration} alt="Card Decoration" />
                                </div>
                                {
                                    this.state.show === 'login' ? (
                                        <div className="login-card-body col-md-6 d-md-block d-flex flex-column justify-content-between">
                                            <div className="login-card-head d-none d-md-block">
                                                <div className="login-card-title">
                                                    Login
                                                </div>
                                                <div className="login-card-subtitle">
                                                    Silahkan gunakan akun yang Anda miliki untuk dapat melanjutkan
                                                </div>
                                            </div>
                                            <form onSubmit={this.handleLogin}>
                                                <div className="mb-3">
                                                    <label className="form-label">Username</label>
                                                    <input value={this.state.data.username} onChange={this.handleChange} type="text" name="username" className={"form-control form-control-sm " + (this.state.error.title !== '' && "is-invalid")} autoComplete="username" />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Password</label>
                                                    <input value={this.state.data.password} onChange={this.handleChange} type="password" name="password" className={"form-control form-control-sm " + (this.state.error.title !== '' && "is-invalid")} autoComplete="current-password" />
                                                    <div className="invalid-feedback">{this.state.error.title}</div>
                                                </div>
                                                <div className="other-action mt-0 mb-3">
                                                    <Link to="/reset-password" className="back-to-app">
                                                        Lupa Password?
                                                    </Link>
                                                </div>
                                                <button type="submit" className="btn btn-sm btn-submit-login">Masuk</button>
                                            </form>
                                            {
                                                this.state.redirect_uri ? (
                                                    <div className="other-action">
                                                        <a className="back-to-app" href={this.state.redirect_uri}>
                                                            <svg className="feather feather-arrow-left me-2" xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                                                            <span>Kembali ke Aplikasi</span>
                                                        </a>
                                                    </div>
                                                ) : (
                                                    <div className="other-action">
                                                        <Link to="/register" className="back-to-app">
                                                            Tidak memiliki akun? Daftar disini
                                                        </Link>
                                                    </div>
                                                )   
                                            }
                                        </div>
                                    ) : (
                                        <div className="login-card-body col-md-6 d-md-block d-flex flex-column justify-content-between" style={{paddingTop: '8rem', paddingBottom: '8rem'}}>
                                            <div className="login-card-head d-block text-center mb-5">
                                                <div className="login-card-title">
                                                    {this.state.error.title}
                                                </div>
                                                <div className="login-card-subtitle">
                                                    {this.state.error.subtitle}
                                                </div>
                                            </div>
                                            <button onClick={this.reset} className="btn btn-sm btn-submit-login">Login Ulang</button>
                                            {
                                                this.state.redirect_uri && (
                                                    <div className="other-action mt-4 text-center">
                                                        <a className="back-to-app" href={this.state.redirect_uri}>
                                                            <svg className="feather feather-arrow-left me-2" xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                                                            <span>Kembali ke Aplikasi</span>
                                                        </a>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

        /* Show 2FA Form */
        else if(this.state.show === '2fa')
        {
            return (
                <React.Fragment>
                    <Loading show={this.state.isLoading} />
                    <div className="login-root d-flex align-items-center justify-content-center">
                        <div className="card-2fa">
                            <div className="card-2fa-body d-flex flex-column justify-content-between">
                                <div className="card-2fa-illustration">
                                    <img src={Illustration2FA} alt="" />
                                </div>
                                <div className="card-2fa-head text-center">
                                    <div className="card-2fa-title">
                                        Autentikasi Dua-Faktor
                                    </div>
                                    <div className="card-2fa-subtitle">
                                        Untuk dapat melanjutkan, silahkan masukkan kode verifikasi yang telah dikirim ke email anda.
                                    </div>
                                </div>
                                <form onSubmit={this.verify2fa}>
                                    <div className="mb-3">
                                        <input onChange={this.handleCode} value={this.state.code} type="tel" name="code" className={"text-center form-control form-control-sm " + (this.state.error.title !== '' && 'is-invalid')} autoFocus />
                                        <div className="invalid-feedback text-center">{this.state.error.title}</div>
                                    </div>
                                    <button type="submit" className="btn btn-sm btn-submit-login">Verifikasi</button>
                                </form>
                                {/* <div className="card-2fa-footer text-center">
                                    <p>Tidak mendapatkan kode? <span>Kirim ulang</span></p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

        /* Show Nothing */
        else
        {
            return null;
        }
    }
}
 
export default Login;