import fetchNow from './_Fetch.jsx';

const UserAPI = {
    get: (id) => {
        return fetchNow('/api/user/' + id, 'GET');
    },

    changePassword: (body) => {
        return fetchNow('/reset-password?o=sso', 'POST', body, 'JSON')
    },
    put: (id, body) => {
        return fetchNow('/api/user/' + id, 'PUT', body, 'JSON');
    }
}
 
export default UserAPI;