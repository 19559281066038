import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class Landing extends Component {
    render() { 
        return (
            <React.Fragment>
                <Redirect to="/login" className="btn btn-danger">Login</Redirect>
            </React.Fragment>
        );
    }
}
 
export default Landing;