import fetchNow from './_Fetch.jsx';

const AuthAPI = {
    /* Login Protocol */
    login: (body) => {
        return fetchNow('/login', 'POST', body, null, true);
    },
    relogin: (body) => {
        return fetchNow('/login?type=continue', 'POST', body, null, true);
    },

    /* Verify 2FA */
    verify: (body) => {
        return fetchNow('/verify', 'POST', body, null, true);
    },

    /* ============================================================================= */

    /* Get Token from SSO Code */
    getToken: (code) => {
        return fetchNow('/sess/' + code, 'POST', null, null, true);
    },
    
    /* Register Account */
    register: (body) => {
        return fetchNow('/signup', 'POST', body, 'JSON', true);
    },
    
    /* Verify Email */
    verifyEmail: (user, code) => {
        return fetchNow(`/verify?u=${user}&code=${code}`, 'GET', null, null, true);
    },

    /* ============================================================================= */

    /* Get User Data */
    user: () => {
        return fetchNow('/user', 'POST')
    },

    /* Request to Forgot Password */
    forgotPassword: (username) => {
        return fetchNow('/forgot-password?u=' + username, 'POST', null, null, true);
    },

    /* Change Password */
    changePassword: (body) => {
        return fetchNow('/reset-password?o=email', 'POST', body, 'JSON', true);
    },

    /* ============================================================================= */

    /* Logout Protocol */
    logout: (body) => {
        return fetchNow('/logout', 'POST', body);
    }
}
 
export default AuthAPI;